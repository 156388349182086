export const consents = [
    {
        name: 'position',
        text: 'inviare la posizione'
    },
    {
        name: 'newsletter_product',
        text: 'update del prodotto'
    },
    {
        name: 'newsletter_commercial',
        text: 'informazioni commerciali'
    },
    {
        name: 'email_notifications',
        text: 'notifiche via mail'
    }
]


