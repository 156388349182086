import Vue from 'vue'
const state = {
  token: null,
  isLogged: false
}

const mutations = {
  set_token: function (state, t) {
    console.log('set token called')
    state.token = t
    Vue.axios.defaults.headers.common['Authorization'] = t
  },

  set_logged: function (state, l) {
    state.isLogged = l
  },

  logout: function (state) {
    state.isLogged = false
    state.token = null
    Vue.axios.defaults.headers.common['Authorization'] = null
  }
}

const getters = {
  isLogged: function (state) {
    return state.isLogged
  },
  getToken: function (state) {
    return state.token
  }
}

const actions = {
  login: function ({commit}, t) {
    commit('set_token', t)
    commit('set_logged', true)
  }
}
export default {
  state,
  getters,
  mutations,
  actions
}
