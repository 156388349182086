/* eslint-disable */
<template>
  <div>
    <div v-if="picture" class="md-layout">
      <md-card class="md-layout-item md-size-30 md-small-size-100 user-form">
        <md-card-header>
          <div class="md-title">Your Profile Picture</div>
        </md-card-header>
        <md-card-content>
          <md-avatar class="md-large">
            <img :src="picture !== 'default' ? picture.url : require('../assets/picture.png')" alt="Avatar">
          </md-avatar>
        </md-card-content>

        <md-progress-bar md-mode="indeterminate" v-if="picture_sending" />

        <md-card-actions>
          <md-button @click="resyncPicture()" class="md-primary" :disabled="picture_sending">Resync Picture</md-button>
          <md-button @click="deletePicture()" class="md-primary" :disabled="picture_sending">Delete Picture</md-button>
        </md-card-actions>
      </md-card>

    </div>
    <form novalidate class="md-layout" @submit.prevent="validateUser">
      <md-card class="md-layout-item md-size-30 md-small-size-100 user-form">
        <md-card-header>
          <div class="md-title">Your data</div>
        </md-card-header>
        <md-card-content>
          <md-field>
            <label for="email">Email</label>
            <md-input name="email" id="email" v-model="email" disabled readonly />
          </md-field>

          <md-field :class="getValidationClass('name')">
            <label for="name">Name</label>
            <md-input name="name" id="name" v-model="form.name" :disabled="sending" />
            <span class="md-error" v-if="!$v.form.name.minLength">Name must have at least 2 chars</span>
            <span class="md-error" v-else-if="!$v.form.name.maxLength">Name must have at most 255 chars</span>
          </md-field>

          <div class="md-layout md-gutter">
            <div class="md-layout-item">
              <md-field :class="getValidationClass('birthday')">
                <label for="birthday">Birthday (yyyy-mm-dd)</label>
                <md-input name="birthday" id="birthday" v-model="form.birthday" :disabled="sending" />
                <span class="md-error" v-if="!$v.form.birthday.date">Date must be in yyyy-mm-dd format</span>
              </md-field>
            </div>
            <div class="md-layout-item">
              <md-field>
                <label for="gender">Gender</label>
                <md-select name="gender" id="gender" v-model="form.gender" md-dense :disabled="sending">
                  <md-option :value="EMPTY_VALUE">{{ EMPTY_VALUE }}</md-option>
                  <md-option value="o">Other</md-option>
                  <md-option value="m">Male</md-option>
                  <md-option value="f">Female</md-option>
                </md-select>
                <span v-if="unexpectedValues.gender" class="md-helper-text">
                  Your gender is set to the unexpected value: '{{ unexpectedValues.gender }}'.
                  Change it selecting one of the provided options.
                </span>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item">
              <md-field>
                <label for="country">Country</label>
                <md-select name="country" id="country" v-model="form.country" md-dense :disabled="sending">
                  <md-option :value="EMPTY_VALUE">{{ EMPTY_VALUE }}</md-option>
                  <md-option v-for="c in countries.codes" :key="c.alpha2" :value="c.alpha2">{{c.name}}</md-option>
                </md-select>
                <span v-if="unexpectedValues.country" class="md-helper-text">
                  Your country is set to the unexpected value: '{{ unexpectedValues.country }}'.
                  Change it selecting one of the provided options.
                </span>
              </md-field>
            </div>
            <div class="md-layout-item">
              <md-field>
                <label for="timezone">Timezone</label>
                <md-select name="timezone" id="timezone" v-model="form.timezone" md-dense :disabled="sending">
                  <md-option :value="EMPTY_VALUE">{{ EMPTY_VALUE }}</md-option>
                  <md-option v-for="t in timezones" :key="t.value" :value="t.value">{{t.name}}</md-option>
                </md-select>
                <span v-if="unexpectedValues.timezone" class="md-helper-text">
                  Your timezone is set to the unexpected value: '{{ unexpectedValues.timezone }}'.
                  Change it selecting one of the provided options.
                </span>
              </md-field>
            </div>
          </div>

          <md-field :class="getValidationClass('language')">
            <label for="language">Language</label>
            <md-input name="language" id="language" v-model="form.language" :disabled="sending" />
            <span class="md-error" v-if="!$v.form.language.minLength">Language must have at least 2 chars</span>
            <span class="md-error" v-else-if="!$v.form.language.maxLength">Language must have at most 255 chars</span>
          </md-field>

          <md-field :class="getValidationClass('location')">
            <label for="location">Location</label>
            <md-input name="location" id="location" v-model="form.location" :disabled="sending" />
            <span class="md-error" v-if="!$v.form.location.minLength">Location must have at least 2 chars</span>
            <span class="md-error" v-else-if="!$v.form.location.maxLength">>Location must have at most 255 chars</span>
          </md-field>

        </md-card-content>

        <md-progress-bar md-mode="indeterminate" v-if="sending" />

        <md-card-actions>
          <md-button type="submit" class="md-primary" :disabled="sending">Save Data Preferences</md-button>
        </md-card-actions>
      </md-card>

    </form>
    <md-card class="md-layout-item md-size-30 md-small-size-100 user-form">
        <md-card-header>
          <div class="md-title">Your Consents</div>
        </md-card-header>
        <md-card-content>
          <md-checkbox v-for="c in userConsents" :key="c.name" v-model="c.allow">{{c.text}}</md-checkbox>
        </md-card-content>

        <md-progress-bar md-mode="indeterminate" v-if="contents_sending" />

        <md-card-actions>
          <md-button @click="saveConsents()" class="md-primary" :disabled="contents_sending">Save Contents Preferences</md-button>
        </md-card-actions>
      </md-card>
      <div class="buttons">
        <md-button class="md-primary logout-button" @click="showDeleteDialog = true">Delete</md-button>
    <md-button class="md-primary logout-button" @click="download()">Download your data</md-button>
    <md-button class="md-primary logout-button" @click="logout()">Logout</md-button>
      </div>
    

    <md-snackbar v-model:md-active="notifyDownload">An email was sent to your email address</md-snackbar>
    <md-snackbar v-model:md-active="notify200">Changes saved with success!</md-snackbar>
    <md-snackbar v-model:md-active="notify500">Something went wrong! Please try again later</md-snackbar>
    <md-dialog-confirm
      v-model:md-active="showDeleteDialog"
      md-title="Confirm"
      md-content="Do you really want to delete all of your data?"
      md-confirm-text="Yes"
      md-cancel-text="No"
      @md-confirm="onDeleteConfirm" />

    <md-dialog-alert
      v-model:md-active="noMailDialog"
      md-title="Warning!"
      md-content="Since you signed up using your phone we don't have any contact email to send your data export to. Please contact us: privacy@wiman.me"/>
  </div>

</template>

<script>
import { minLength, maxLength } from 'vuelidate/lib/validators'
import { codes, byAlpha2 } from 'iso-country-codes'
import { tzs } from '../timezones'
import { consents } from '../consents'
const dateRegExp = new RegExp("^(1(8|9)[0-9]{2}|[2-9][0-9]{3})-((0(1|3|5|7|8)|10|12)-(0[1-9]|1[0-9]|2[0-9]|3[0-1])|(0(4|6|9)|11)-(0[1-9]|1[0-9]|2[0-9]|30)|(02)-(0[1-9]|1[0-9]|2[0-9]))$")
const takeIfNot = (v, s) => (v == s) ? null : v

export default {
  name: 'home',
  data: () => ({
    noMailDialog: false,
    notifyDownload: false,
    showDeleteDialog: false,
    consents: consents,
    userConsents: null,
    boolean: false,
    deleting: false,
    EMPTY_VALUE: "<empty>",
    countries: { codes, byAlpha2 },
    timezones: tzs,
    email: null,
    picture: null,
    form: {
      birthday: null,
      country: null,
      gender: null,
      language: null,
      location: null,
      name: null,
      timezone: null,
    },
    unexpectedValues: {
      country: null,
      gender: null,
      timezone: null
    },
    notify200: false,
    notify500: false,
    sending: false,
    picture_sending: false,
    contents_sending: false
  }),
  validations: {
    form: {
      birthday: {
        date: (s) => s == null || s.length == 0 || dateRegExp.test(s)
      },
      language: {
        minLength: minLength(2),
        maxLength: maxLength(16)
      },
      location: {
        minLength: minLength(2),
        maxLength: maxLength(255)
      },
      name: {
        minLength: minLength(2),
        maxLength: maxLength(255)
      }
    }
  },
  methods: {
    async download () {
      if (!this.email) {
        this.noMailDialog = true
        return
      }
      try {
        let r = await this.axios.post('/api/users/me/download')
        this.notifyDownload = true
      } catch (e) {
        this.notify500 = true
      }
    
    },
    async onDeleteConfirm () {
      try {
        let r = await this.axios.post('/api/users/me/delete')
        this.logout()
      } catch (e) {
        this.notify500 = true
      }
    }, 
    async deletePicture () {
      this.picture_sending = true
      try {
        let r = await this.axios.post('/api/users/me/deletepicture')
        this.notify200 = true
        this.picture = 'default'
        this.picture_sending = false
      } catch (e) {
        this.notify500 = true
        console.log(e)
      }
      this.picture_sending = false
    },
    async resyncPicture () {
      this.picture_sending = true
      try {
        let r = await this.axios.post('/api/users/me/resyncpicture')
        this.notify200 = true
        this.picture = r.data
      } catch (e) {
        this.notify500 = true
        console.log(e)
      }

      this.picture_sending = false
    },
    logout () {
      this.$store.commit('logout')
      this.$router.push('/login')
    },
    getValidationClass (fieldName) {
      const field = this.$v.form[fieldName]
      if (field) return { 'md-invalid': field.$invalid && field.$dirty }
    },
    async saveUser () {
      this.sending = true
      try {
        await this.axios.post('/api/users/me', {
          birthday: takeIfNot(this.form.birthday, ""),
          country: takeIfNot(this.form.country, this.EMPTY_VALUE),
          gender: takeIfNot(this.form.gender, this.EMPTY_VALUE),
          language: this.form.language,
          location: this.form.location,
          name: this.form.name,
          timezone: takeIfNot(this.form.timezone, this.EMPTY_VALUE),
        })
        this.notify200 = true
        this.sending = false
      } catch(err) {
        console.error(err)
        this.sending = false
        this.notify500 = true
      }
    },
    validateUser () {
      this.$v.$touch()
      if (!this.$v.$invalid) this.saveUser()
    },
    async saveConsents () {
      try {
        this.contents_sending = true
        let allow = this.userConsents.filter(c => c.allow === true).map(c => {return {name: c.name}})
        console.log('allow', allow)
        let deny = this.userConsents.filter(c => c.allow === false).map(c => {return {name: c.name}})
        console.log('deny', deny)
        if (allow.length)
          await this.axios.post('/api/consents/me', {consents: allow})
        if (deny.length)
          await this.axios.post('/api/consents/me/delete', {consents: deny})
        this.notify200 = true
      } catch (e) {
        console.log(e)
        this.notify500 = true
      }
      this.contents_sending = false
    }
  },
  async created () {
    if (!this.$store.getters.isLogged) return this.$router.push('/login')
    let user = await this.axios.get('/api/users/me')
    let consents = await this.axios.get('/api/consents/me')
    this.userConsents = this.consents.map(c => {return {...c, allow: consents.data.find(e => e.name === c.name) ? true : false}})
    this.email = user.data.email
    this.form.birthday = user.data.birthday ? user.data.birthday.split("T")[0] : null
    this.form.language = user.data.language
    this.form.location = user.data.location
    this.form.name = user.data.name

    // Country parse and set
    if (user.data.country == null)
      this.form.country = this.EMPTY_VALUE
    else if (!this.countries.byAlpha2[user.data.country.toUpperCase()]) {
      this.unexpectedValues.country = user.data.country
      this.form.country = this.EMPTY_VALUE
    } else this.form.country = user.data.country.toUpperCase()

    // Gender parse and set
    if (user.data.gender == null)
      this.form.gender = this.EMPTY_VALUE
    else if (["o", "m", "f"].indexOf(user.data.gender.toLowerCase()) == -1) {
      this.unexpectedValues.gender = user.data.gender
      this.form.gender = this.EMPTY_VALUE
    } else this.form.gender = user.data.gender.toLowerCase()

    // Timezone parse and set
    if (user.data.timezone == null) {
      this.form.timezone = this.EMPTY_VALUE
    } else {
      const userTZ = parseFloat(user.data.timezone)
      if (isNaN(userTZ) || this.timezones.map((tz) => tz.value).indexOf(userTZ) == -1) {
        this.unexpectedValues.timezone = userTZ
        this.form.timezone = this.EMPTY_VALUE
      } else this.form.timezone = userTZ
    }

    this.picture = user.data.picture

    console.log(user)
  }
}
</script>

<style lang="sass">
.md-checkbox
  display: flex
.user-form
  margin: 0 auto
.logout-button
  display: inline-block
  margin: 24px auto 0px auto
.buttons
  text-align: center
</style>
