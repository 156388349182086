export const tzs = [
  {
    name: "UTC -12:00, Y",
    value: -12.00
  },
  {
    name: "UTC -11:00, X",
    value: -11.00
  },
  {
    name: "UTC -10:00, W",
    value: -10.00
  },
  {
    name: "UTC -09:30, V†",
    value: -9.30
  },
  {
    name: "UTC -09:00, V",
    value: -9.00
  },
  {
    name: "UTC -08:00, U",
    value: -8.00
  },
  {
    name: "UTC -07:00, T",
    value: -7.00
  },
  {
    name: "UTC -06:00, S",
    value: -6.00
  },
  {
    name: "UTC -05:00, R",
    value: -5.00
  },
  {
    name: "UTC -04:00, Q",
    value: -4.00
  },
  {
    name: "UTC -03:30, P†",
    value: -3.30
  },
  {
    name: "UTC -03:00, P",
    value: -3.00
  },
  {
    name: "UTC -02:00, O",
    value: -2.00
  },
  {
    name: "UTC -01:00, N",
    value: -1.00
  },
  {
    name: "UTC ±00:00, Z",
    value: 0.00
  },
  {
    name: "UTC +01:00, A",
    value: 1.00
  },
  {
    name: "UTC +02:00, B",
    value: 2.00
  },
  {
    name: "UTC +03:00, C",
    value: 3.00
  },
  {
    name: "UTC +03:30, C†",
    value: 3.30
  },
  {
    name: "UTC +04:00, D",
    value: 4.00
  },
  {
    name: "UTC +04:30, D†",
    value: 4.30
  },
  {
    name: "UTC +05:00, E",
    value: 5.00
  },
  {
    name: "UTC +05:30, E†",
    value: 5.30
  },
  {
    name: "UTC +05:45, E*",
    value: 5.45
  },
  {
    name: "UTC +06:00, F",
    value: 6.00
  },
  {
    name: "UTC +06:30, F†",
    value: 6.30
  },
  {
    name: "UTC +07:00, G",
    value: 7.00
  },
  {
    name: "UTC +08:00, H",
    value: 8.00
  },
  {
    name: "UTC +08:45, H†",
    value: 8.45
  },
  {
    name: "UTC +09:00, I",
    value: 9.00
  },
  {
    name: "UTC +09:30, I†",
    value: 9.30
  },
  {
    name: "UTC +10:00, K",
    value: 10.00
  },
  {
    name: "UTC +10:30, K†",
    value: 10.30
  },
  {
    name: "UTC +11:00, L",
    value: 11.00
  },
  {
    name: "UTC +12:00, M",
    value: 12.00
  },
  {
    name: "UTC +12:45, M*",
    value: 12.45
  },
  {
    name: "UTC +13:00, M†",
    value: 13.00
  },
  {
    name: "UTC +14:00, M†",
    value: 14.00
  }
]