<template>
  <div id="app">
    <img id="logo" src="./assets/logo-wiman.svg" />
    <router-view/>
  </div>
</template>
<script>
export default {
  created () {
    if (this.$store.getters.isLogged) {
      this.axios.defaults.headers.common['Authorization'] = this.$store.getters.getToken
    }
  }
}
</script>
<style lang="sass">
#logo
  display: block
  margin: 48px auto
</style>