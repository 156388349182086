/* eslint-disable */
<template>
  <div>
    <md-button :href="'/auth/google'" class="md-raised md-primary login-button login-button-google">
      <img src="../assets/icon-google.png"/>
      Log in with Google
    </md-button>
    <md-button :href="'/auth/facebook'" class="md-raised md-primary login-button login-button-facebook">
      <img src="../assets/icon-facebook.png"/>
      Log in with Facebook
    </md-button>
    <md-button :href="'/auth/instagram'" class="md-raised md-primary login-button login-button-instagram">
      <img src="../assets/icon-instagram.png"/>
      Log in with Instagram
    </md-button>
    <p class="login-error">{{error}}</p>
  </div>
</template>

<script>

export default {
  name: 'login',
  data: () => ({
    error: null
  }),
  created () {
    if (this.$route.query.error) {
      this.error = this.$route.query.error
    } else if (this.$route.query.token) {
      this.$store.dispatch('login', this.$route.query.token)
      this.$router.push('/')
      return
    }
  }
}
</script>

<style lang="sass">
.login-button
  width: 240px
  display: block
  margin: 24px auto 0px auto !important
  border-radius: 24px
  text-transform: none
  .md-ripple
    justify-content: start
  .md-button-content
    height: 48px
    padding: 8px 24px
    img
      max-height: 100%

.login-button-google
  background-color: #db4437 !important

.login-button-facebook
  background-color: #4267b2 !important

.login-button-instagram
  background-color: #833AB4 !important

.login-error
  margin-top: 24px
  text-align: center
  font-weight: bold
</style>