import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueMaterial from 'vue-material'
import Vuelidate from 'vuelidate'

Vue.config.devtools = true
// Vue.config.productionTip = false

axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  console.log('interceptor KO', error.response.status)
  if (error.response.status === 401) {
    store.commit('logout')
    router.push('/login')
  }
  return Promise.reject(error)
})

//axios.defaults.baseURL = process.env.MY_ENV !== 'local' ? '/api' : 'http://localhost:3000/api'
Vue.use(VueAxios, axios)
Vue.use(VueMaterial)
Vue.use(Vuelidate)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
